import TypeOfAnalyticsMess from "../enums/analytics";
import OperationSystem from "../enums/os";
import IArticle from "../interfaces/IArticle"
import ISupportData from "../interfaces/ISupportData";
import '../scss/Popular.scss';
import Article from "./Article";

type PopularPropsType = {
    articles: IArticle[] | undefined
    supportData?: ISupportData
    bundle_id: string | null
    locale: string | null
    app_version: string | null
    os: OperationSystem
    expand: string | null
    sendAnalytics: (analyticsType: TypeOfAnalyticsMess, path: string, articleId?: number, articleTitle?: string, queryBody?: string) => void
    appSendFormData: (formData: any) => void
    changeBirthDate: (field: string, value: string) => void
}

const Popular = (props: PopularPropsType): JSX.Element => {
    return (
        props.articles?.length ?
            <>
                <div className="popular-header">Популярное</div>
                {props.articles?.map(article => (
                    <Article
                        key={article.id}
                        id={article.id}
                        dv_id={article.dv_id}
                        is_decorate={article.is_decorate}
                        title={article.title}
                        body={article.body}
                        expand={props.expand}
                        article_images={article.article_images}
                        automatization_tag={article.automatization_tag}
                        supportData={props.supportData}
                        allow_send={article.allow_send}
                        locale={props.locale}
                        os={props.os}
                        app_version={props.app_version}
                        bundle_id={props.bundle_id}
                        sendAnalytics={props.sendAnalytics}
                        appSendFormData={props.appSendFormData}
                        changeBirthDate={props.changeBirthDate} />
                ))}
            </> : <></>
    )
}

export default Popular